/**
 * Dialog to show schedule log
 */
import template from './FollowScheduleLog.html';
import WebSocketModel from '../lib/WebSocketModel.js';

class FollowScheduleLogViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;

		this.data = '';
		this.lines = ko.observable('');
		this.socket = null;
		this.socket_status = ko.observable('Closed');
		this.schedule_id = null;

		this.init();
	}

	init ()
	{
		this.socket = new WebSocketModel(`/api/bgworker/schedules/follow-log?schedule_id=${this.schedule_id}&file=stdout`);
		this.socket.on_message = (msg) => {
			this.data = this.data + '\n' + msg;
			this.lines(this.data);
		};
		this.socket.open('text');
	}

	close ()
	{
		if (this.socket)
			this.socket.close();
	}
}


class FollowScheduleLogDialog
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.name = 'FollowScheduleLogDialog';
		this.viewModel = new FollowScheduleLogViewModel(this);
		this.process = null;
		this.viewModel.schedule_id = bindings.schedule_id;
	}

	async init ()
	{
		this.viewModel.init();
	}

	btnClose_click ()
	{
		this.close(false);
	}

	async btnDownload_click ()
	{
	}

	async teardown ()
	{
		await this.viewModel.close();
	}
}

export default {
	name: 'FollowScheduleLog',
	dialog_class: FollowScheduleLogDialog,
	template: template,
	provider: "ps"
}
