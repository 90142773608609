import template from './all.html';
import ScheduleVM from '../../ScheduleVM.js';

class AllProcessesViewModel
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.pid = ko.observable(null);
		this.processes = ko.observableArray([]);
		this.monitored_schedules = {};
		this.selected_category = ko.observable();
	}

    async load()
	{
		this.processes([]);

		let request_object = {
			schema: 'bgworker',
			table: 'v_processes'
		};
		let cat = this.selected_category();
		if (cat != 'All')
			request_object.filter = [{field: 'process_category', operator: '=', value: cat}];
		let result = await Grape.fetches.getJSON('/api/record', request_object);

		let process_records = [];
		for (let rec of result.records)
		{
			let process = {
				active: rec.active,
				description: rec.description,
				ui_param: rec.ui_param || [],
				process_category: rec.process_category,
				process_id: rec.process_id,
				process_name: rec.process_name,
				can_execute: rec.can_execute,
				schedules: ko.observableArray([]),
				auto_schedulers: []
			};

			let auto_schedulers = [];
			for (let a of (rec.auto_schedulers || []))
			{
				if (a.scheduled_interval == null)
					a.interval = '';
				else
				{
					let ar = a.scheduled_interval.split(':');
					a.interval = `Every ${ar[0]} hrs ${ar[1]} mins`; //a.scheduler_formatter;
				}

				// Convert dayofweek string from internal format '0011001' to '--TW--S' for display
				let dow = a.dow; 
				if (dow == '0000000')
					dow = '1111111';
				a.dow = '';
				for (let [k,v] of Object.entries(dow.split('')))
					a.dow += v=='1' ? 'SMTWTFS'[k] : '-';

				a.pending_schedules = ko.observableArray([]);
				a.last_completed_schedule = null;
				for (let s of a.schedules)
				{
					if (!s.status)
						s.status = 'New';
					s.auto_scheduler_id = a.auto_scheduler_id;
					let sched = this.monitored_schedules[s.schedule_id];
					if (!sched)
					{
						sched = new ScheduleVM(s);
						this.monitored_schedules[s.schedule_id] = sched;
					}
					else
						sched.set(s);
					
					if (['Completed', 'Error'].indexOf(sched.status) > -1)
						a.last_completed_schedule = sched;
					else
						a.pending_schedules.push(sched);
				}
				a.active = ko.observable(a.active);
				auto_schedulers.push(a);
			}

			// Sort according to auto_scheduler_id
			process.auto_schedulers = auto_schedulers.sort((x, y) => x.auto_scheduler_id - y.auto_scheduler_id);

			let schedules = [];
			for (let s of (rec.pending_schedules || []))
			{
				let sched = this.monitored_schedules[s.schedule_id];
				if (!sched)
				{
					sched = new ScheduleVM(s);
					sched.status('New');
					this.monitored_schedules[s.schedule_id] = sched;
				}
				schedules.push(sched);
			}
			if (rec.last_started_schedule)
			{
				let sched = this.monitored_schedules[rec.last_started_schedule.schedule_id];
				if (!sched)
				{
					sched = new ScheduleVM(rec.last_started_schedule);
					this.monitored_schedules[rec.last_started_schedule.schedule_id] = sched;
				}
				else
					sched.set(rec.last_started_schedule);
				schedules.push(sched);
			}
			process.schedules(schedules);
			process_records.push (process);
		}

		this.processes(process_records);
	}

	// Autoscheduler stuff
	async btnEditAutoScheduler_click (autoscheduler)
	{
		let process = this.processes().find(function(p) {return (p.process_id == autoscheduler.process_id);});
		let result = await Grape.dialog.open(
			'AutoSchedulerDialog',
			{
				process: process,
				autoscheduler: autoscheduler 
			});

		if (result)
			this.load();
	}

	async btnNewAutoScheduler_click (process)
	{
		let result = await Grape.dialog.open(
			'AutoSchedulerDialog',
			{ process: process }
		);
		if (result)
			this.load();
	}

	async btnDisableAutoScheduler_click (process, auto_scheduler)
	{
		let data = {
			auto_scheduler_id: auto_scheduler.auto_scheduler_id,
			active: false
		};
		auto_scheduler.active(null);
		let result = await Grape.fetches.postJSON(`/api/bgworker/process/${process.process_id}/autoscheduler`, data);
		auto_scheduler.active(false);
	}

	async btnEnableAutoScheduler_click (process, auto_scheduler)
	{
		let data = {
			auto_scheduler_id: auto_scheduler.auto_scheduler_id,
			active: true
		};
		auto_scheduler.active(null);
		let result = await Grape.fetches.postJSON(`/api/bgworker/process/${process.process_id}/autoscheduler`, data);
		auto_scheduler.active(true);

	}
	
	schedule_updated (schedule)
	{
		//this.load();
	}

	async btnHistory_click (process)
	{
		let result = await Grape.dialog.open('ViewProcessSchedules', {process});
	}

	async btnEditProcess_click (process)
	{
		let result = await Grape.dialog.open('EditProcess', {process});
	}
	
	async btnNewSchedule_click (process)
    {
		let data = Grape.dialog.open(
			'CreateSchedule',
			{
				process_name: process.process_name,
				process_id: process.process_id,
				params: process.ui_param
			});
	}

	async handleSocketMessage (msg)
	{
		let sched;
		let process;
		switch (msg['event-type'])
		{
			case 'new-schedule':
				sched = this.monitored_schedules[msg.schedule_id];
				if (!sched)
				{
					sched = new ScheduleVM({schedule_id: msg.schedule_id});
					this.monitored_schedules[msg.schedule_id] = sched;
				}

				await sched.load();

				process = this.processes().find(function(p) { return (p.process_id == msg.process_id); });
				if (sched.auto_scheduler_id())
				{
					let auto_scheduler =
						process.auto_schedulers.find(
							(a) => a.auto_scheduler_id == sched.auto_scheduler_id()
						);
					if (!auto_scheduler)
						return;
					auto_scheduler.pending_schedules.push(sched);
				}
				else
					process.schedules.unshift(sched);
				
				break;
			case 'schedule-status-update':
				if (this.monitored_schedules[msg.schedule_id])
				{
					this.monitored_schedules[msg.schedule_id].status(msg.status);
					await this.monitored_schedules[msg.schedule_id].load();
				}
				break;
			case 'delete-schedule':
				sched = this.monitored_schedules[msg.schedule_id];
				if (!sched)
					return;

				process = this.processes().find(function(p) { return (p.process_id == msg.process_id); });
				if (!process)
					return;
				if (sched.auto_scheduler_id())
				{
					let auto_scheduler =
						process.auto_schedulers.find(
							(a) => a.auto_scheduler_id == sched.auto_scheduler_id()
						);
					if (!auto_scheduler)
						return;
					let pos = auto_scheduler.pending_schedules().find((s) => s.schedule_id() == msg.schedule_id);
					if (!pos)
						return;
					auto_scheduler.pending_schedules.splice(pos, 1);
				}
				else
				{
					let pos = process.schedules().find((s) => s.schedule_id() == msg.schedule_id);
					if (!pos)
						return;
					process.schedules.splice(pos, 1);
				}

				delete this.monitored_schedules[msg.schedule_id];

				break;
			default:
				break;
		}
	}

	/**
	 * This is kak. Need to keep track of bgworker status bar for disposal
	 */
	async bgworkerStatusBarInit(sb)
	{
		this.bgworkerStatusBar = sb;
	}

	/**
	 * Teardown callback
	 */
	teardown()
	{
		if (this.bgworkerStatusBar)
			this.bgworkerStatusBar.dispose();
	}
}

class AllProcessesPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new AllProcessesViewModel(this);
		this.bindings = bindings;
		this.timer = null;

        this.viewModel.selected_category(bindings.title || 'All');
	}

	async init ()
	{
		document.title = 'Processes';

        // Need to be logged in to view this page
		if (!window.Grape.currentSession || !window.Grape.currentSession.session_id)
		{
			window.Grape.navigate(`/grape-ui/login?rr=${encodeURI('/ui/bgworker/processes')}`);
			return;
		}
	}

    async updateData ()
	{
		if (!window.Grape.currentSession || !window.Grape.currentSession.session_id)
			return;
		return this.viewModel.load();
	}

	teardown ()
	{
		this.viewModel.teardown();
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/processes/]subpage',
	page_id: 'processes/subpage',
	page_class: AllProcessesPage,
	template: template
};
