
/**
 * Class to help talk to the server for getting bgworker status
 */
class BGWorkerStatusVM
{
	constructor(params)
	{
		this.socket_cb = params.socket_cb; // socket message callback

		this.socket_status = ko.observable('Closed');
		this.socket_messages = ko.observableArray([]);
		this.bgw_pid = ko.observable(0);
		this.bgw_state = ko.observable('');
		this.bgw_utz_summ = ko.observable('');
		this.bgw_utz_det = ko.observable('');
		this.paused = ko.observable(false);
	
		this.status_update_timer = null;
		this.last_status_update_rcvd = null;
		this.last_socket_message_rcvd = null;

		this._socket_message_cb = (...p) => { this.handle_socket_message(...p) }
	
		this.update();
		this.open_socket();
		this.status_update_timer = setInterval(() => { this.update(); }, 5000);

		if (params.init_cb instanceof Function)
			params.init_cb(this);
	}

	async update()
	{
		// if last time this function was after the last time a socket message has been received
		if (this.last_status_update_rcvd > this.last_socket_message_rcvd)
			return;

		let result = await Grape.fetches.getJSON('/api/bgworker/status');
		if (result?.status == 'OK')
		{
			this.bgw_pid(result.pid || '');
			this.bgw_state(result.state || '');
			this.bgw_utz_summ(result.utz_summ);
			this.bgw_utz_det(result.utz_det);
			this.last_status_update_rcvd = new Date();
			this.paused(result.paused);
		}
	}

	async open_socket()
	{
		const socket = window.BGWorkerUpdateSocket;

		this.socket_status = socket.status_observable;
		socket.on('message', this._socket_message_cb);
	}

	async handle_socket_message(msg)
	{
		this.last_socket_message_rcvd = new Date();
		switch (msg['event-type'])
		{
			case 'new-schedule':
				this.socket_messages.unshift(`New schedule ${msg.schedule_id} for process ${msg.process_id}`);
				break;
			case 'schedule-status-update':
				this.socket_messages.unshift(`Schedule ${msg.schedule_id} status updated to ${msg.status}`);
				break;
			case 'delete-schedule':
				this.socket_messages.unshift(`Schedule ${msg.schedule_id} deleted`);
				break;
			default:
				this.socket_messages.unshift(JSON.stringify(msg));
				break;
		}

		this.socket_cb(msg);
	}

	async pause_bgworker()
	{
		const result = await Grape.fetches.fetchJSON('/api/setting/bgworker.paused', {
			method: 'PATCH',
			headers: {'Content-type': 'application/json'},
			body: JSON.stringify({value: true})
		});
		this.last_status_update_rcvd = 0;
		this.update();
	}

	async unpause_bgworker()
	{
		const result = await Grape.fetches.fetchJSON('/api/setting/bgworker.paused', {
			method: 'PATCH',
			headers: {'Content-type': 'application/json'},
			body: JSON.stringify({value: false})
		});
		this.last_status_update_rcvd = 0;
		this.update();

	}

	dispose()
	{
		window.BGWorkerUpdateSocket.off('message', this._socket_message_cb);
		if (this.status_update_timer)
			clearInterval(this.status_update_timer);
	}
}

export default BGWorkerStatusVM;
