class ProcessConfigVM
{
	constructor (dialog)
	{
		this.dialog = dialog;

		this.process_id = ko.observable();
		this.active = ko.observable();
		this.process_name = ko.observable();
		this.process_category = ko.observable();
		this.description = ko.observable();
		this.process_type = ko.observable();
		this.function_schema = ko.observable();
		this.function_name = ko.observable();
		this.script_path_name = ko.observable();
		this.script_name = ko.observable();
		this.ui_param = ko.observableArray();
		this.ui_param_str = ko.observable();
		this.process_roles = ko.observableArray();
		this.process_limits = ko.observableArray();
		this.plugin_consumers = ko.observableArray();

		this.limits = {
			max_runtime: ko.observable(),
			max_parallel: ko.observable(),
			max_new: ko.observable(),
			duplicate_threshold: ko.observable(),
			schedule_retention: ko.observable(),
			disable_after_error: ko.observable()
		};

	}

	serialize ()
	{
		let ui_param = null;
		try {
			ui_param = JSON.parse(this.ui_param_str());
		} catch (err) {
			throw new Error('Input parameters invalid JSON');
		}

		const obj = {
			process_id: this.process_id(),
			process_name: this.process_name(),
			active: this.active(),
			description: this.description(),
			process_category: this.process_category(),
			process_type: this.process_type(),
			function_name: this.function_name(),
			function_schema: this.function_schema(),
			ui_param: ui_param,
			limits: {
				max_runtime: this.limits.max_runtime(),
				max_parallel: this.limits.max_parallel(),
				max_new: this.limits.max_new(),
				duplicate_threshold: this.limits.duplicate_threshold(),
				schedule_retention: this.limits.schedule_retention(),
				disable_after_error: this.limits.disable_after_error(),
			},
			process_roles: [],
			consumers: this.plugin_consumers()
		};

		for (let pr of this.process_roles())
			obj.process_roles.push({
				role_name: pr.role_name,
				can_view: pr.can_view(),
				can_edit: pr.can_edit(),
				can_execute: pr.can_execute()
			});

		if (this.process_type() != 'DB')
		{
			obj.function_schema = this.script_path_name();
			obj.function_name = this.script_name();
		}

		return obj;
	}

	async load ()
	{
		let request_object = {
			schema: 'bgworker',
			table: 'v_processes_config',
			filter: [{field: 'process_id', operator: '=', value: this.process_id()}]
		};
		let result = await Grape.fetches.getJSON('/api/record', request_object);

		if (!(result.records?.length == 1))
			throw new Error('No records found. Expected 1');

		this.set(result.records[0]);
	}

	set (obj)
	{
		if (obj.process_name)
			this.process_name(obj.process_name);
		if (obj.active)
			this.active(obj.active);
		if (obj.description)
			this.description(obj.description);
		if (obj.description)
			this.description(obj.description);
		if (obj.process_category)
			this.process_category(obj.process_category);
		if (obj.process_type)
			this.process_type(obj.process_type);
		if (obj.ui_param)
		{
			this.ui_param(obj.ui_param);
			this.ui_param_str(JSON.stringify(obj.ui_param, null, 4));
		}
		if (obj.function_schema)
		{
			if (this.process_type() == 'DB')
				this.function_schema(obj.function_schema);
			else
				this.script_path_name(obj.function_schema);
		}
		if (obj.function_name)
		{
			if (this.process_type() == 'DB')
				this.function_name(obj.function_name); 
			else
				this.script_name(obj.function_name);
		}

		if (obj.limits?.max_runtime)
			this.limits.max_runtime(obj.limits.max_runtime);
		if (obj.limits?.max_parallel)
			this.limits.max_parallel(obj.limits.max_parallel);
		if (obj.limits?.max_new)
			this.limits.max_new(obj.limits.max_new);
		if (obj.limits?.duplicate_threshold)
			this.limits.duplicate_threshold(obj.limits.duplicate_threshold);
		if (obj.limits?.schedule_retention)
			this.limits.schedule_retention(obj.limits.schedule_retention);
		if (obj.limits?.disable_after_error)
			this.limits.disable_after_error(obj.limits.disable_after_error);

		if (obj.process_roles)
		{
			let prs = [];
			for (let pr of obj.process_roles)
			{
				prs.push({
					role_name: pr.role_name,
					can_view: ko.observable(pr.can_view),
					can_execute: ko.observable(pr.can_execute),
					can_edit: ko.observable(pr.can_edit)
				});
			}
			this.process_roles(prs);
		}

		if (obj.plugin_consumers)
			this.plugin_consumers(obj.plugin_consumers);
	}
}

export default ProcessConfigVM;
