
import template from './schedule.html';

import svg_stopwatch from '../assets/hourglass-start-duotone.svg';
//import svg_refresh from '../assets/hourglass-start-duotone.svg';

class ScheduleComponentVM {
	constructor(obj) {
		this.schedule = ko.observable(obj.schedule);

		if (obj.update_cb)
			this.update_cb = obj.update_cb;
		else
			this.update_cb = () => {};

		this.show_logfile = ko.observable(false);
		this.extra_status = ko.observable('');
			
		this.status_label_class = ko.observable('label');
		this.status_label_icon = ko.observable('');

		this.action_button_text = ko.observable();
		this.action_button_class = ko.observable();
		this.action_button_icon = ko.observable();

		this.svg = {
			svg_stopwatch
		};

		let status = this.schedule().status();
		if (status == 'Completed')
		{
			this.status_label_class('label label-success');
			//this.status_label_icon = 'fa fa-check';
			this.show_logfile(true);
		}
		else if (status == 'Running')
		{
			this.status_label_class('label label-info');
			//this.status_label_icon = 'fa fa-cog';
			//if (schedule.perc_complete)
			//	this.extra_status([schedule.perc_complete, '%'].join(''));
			this.action_button_text('Stop');
			this.action_button_icon('fa fa-stop');
			this.action_button_class('btn btn-xs btn-danger');
			this.show_logfile(true);
		}
		else if (status == 'Error')
		{
			//this.status_label_icon = 'fa fa-sad-tear';
			this.status_label_class('label label-warning');
		}
		else if (status == 'New')
		{
			this.status_label_class('label label-warning');
			this.status_label_icon(svg_stopwatch);
			this.action_button_text('Remove');
			this.action_button_icon('fa fa-trash');
			this.action_button_class('btn btn-xs btn-danger');
		}
		else 
		{
			this.status_label_class('label label-warning');
			//this.status_label_icon = 'fa fa-refresh';
			this.show_logfile(true);
		}
	}

	async btnRemoveSchedule_click(schedule)
	{
		await Grape.fetches.fetch(`/api/bgworker/schedule/${schedule.schedule_id()}`, {method: 'DELETE'});
		if (this.update_cb && this.update_cb instanceof Function)
		{
			this.update_cb(null);
		}
	}

	async btnFollowLog_click(schedule)
	{
		let result = await Grape.dialog.open(
			'FollowScheduleLog',
			{
				schedule_id: schedule.schedule_id()
			}
		);
	}

	dispose()
	{
	}
}

export default {
	name: 'process-schedule',
	viewModel: ScheduleComponentVM,
	module_type: 'ko',
	template: template
}

