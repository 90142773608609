/**
 * Import DIALOGS
 */
import AutoScheduler from './AutoScheduler.js';
import ViewProcessSchedules from './ViewProcessSchedules.js';
import CreateSchedule from './CreateSchedule.js';
import EditProcess from './EditProcess.js';
import FollowScheduleLog from './FollowScheduleLog.js';

export default [
	AutoScheduler,
	ViewProcessSchedules,
	CreateSchedule,
	EditProcess,
	FollowScheduleLog
];
