
ko.bindingHandlers.expandObject = {
	init: function() {
		return { controlsDescendantBindings: true };
	},
	update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
		var valueUnwrapped = ko.utils.unwrapObservable(valueAccessor());

		function expandValues(obj) 
		{
			let html = '<ul>';
			Object.keys(obj).forEach(function(k) {
				if (k && typeof k === 'object' && value.constructor === Object)
					html += '<li><span class="object-field-name">' + k + '</span>: ' + expandValues(obj[k]) + '</li>';
				else
					html += '<li><span class="object-field-name">' + k + '</span>: <span class="object-field-value">' + obj[k] + '</span></li>';
			});
			html += '</ul>';

			return html;
		}

		if (valueUnwrapped)
		{
			let obj = {};
			if (typeof valueUnwrapped == 'string')
				obj = JSON.parse(valueUnwrapped);
			else
				obj = valueUnwrapped;

			let html = expandValues(obj);

			ko.applyBindingsToNode(element, { html: html });
			ko.applyBindingsToDescendants(viewModel, element);
		}
		else
			return;
	}
};

