/**
 * Dialog for editing a process definition
 */
import template from './EditProcess.html';
import ProcessConfigModel from './ProcessConfigVM.js';

class EditProcessViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;

		this.ui_param_str = ko.observable();

		this.process_config = new ProcessConfigModel();

		this.process_types = ko.observableArray(['DB', 'EXEC', 'NODE']);
		this.process_functions = ko.observableArray();
		this.selected_process_function = ko.observable();

		this.available_script_path_names = ko.observableArray([]);
		this.available_script_names = ko.observableArray([]);
		this.available_process_schemas = ko.observableArray([]);
		this.available_function_names = ko.observableArray([]);
		this.available_handlers = ko.observableArray([]);

		this.access_roles = ko.observableArray();

		this.new_plugin_consumer = {
			hook_name: ko.observable(),
			handler_name: ko.observable(),
			idx: ko.observable()
		};

		/**
		 * On process_type change, reset inputs
		 */
		this.process_config.process_type.subscribe((nv) => {
			this.process_config.function_name(null);
			this.process_config.script_name(null);
			this.process_config.function_schema(null);
			this.process_config.script_path_name(null);
		});

		/* After script_path change, populate available_script_names */
		this.process_config.script_path_name.subscribe(async (nv) => {
			if (this.process_config.script_path_name())
			{
				const result = await Grape.fetches.getJSON(
					`/api/bgworker/lookup/functions?`
					+ `type=${this.process_config.process_type()}&`
					+ `schema=${this.process_config.script_path_name()}`);
				this.available_script_names(result.names);
			}
			else
				this.available_script_names([]);
		});
		
		/* After function_schema change, populate available_function_names */
		this.process_config.function_schema.subscribe(async (nv) => {
			if (this.process_config.function_schema())
			{
				const result = await Grape.fetches.getJSON(
					`/api/bgworker/lookup/functions`
					+ `?type=${this.process_config.process_type()}`
					+ `&schema=${this.process_config.function_schema()}`);
				this.available_function_names(result.names);
			}
			else
				this.available_function_names([]);
		});

		this.visible_access_roles = ko.computed(() => {
			return this.access_roles().filter((d) => {
				return this.process_config.process_roles().findIndex(function(i) {
					return (i.role_name == d.role_name);
				}) == -1;
			});
		});

		this.new_access_role = ko.observable();
		this.new_can_view = ko.observable(true);
		this.new_can_execute = ko.observable(false);
		this.new_can_edit = ko.observable(false);
	}

	btnAddAccessRole_click ()
	{
		this.process_config.process_roles.push({
			role_name: this.new_access_role().role_name,
			can_view: ko.observable(this.new_can_view()),
			can_execute: ko.observable(this.new_can_execute()),
			can_edit: ko.observable(this.new_can_edit())
		});

		this.new_access_role(null);
	}

	btnRemoveAccessRole_click (obj)
	{
		this.process_config.process_roles.remove((item) => {
			return item.role_name == obj.role_name;
		});
	}

	btnAddPluginConsumer_click (obj)
	{
		this.process_config.plugin_consumers.push({
			hook_name: this.new_plugin_consumer.hook_name(),
			handler_name: this.new_plugin_consumer.handler_name().name,
			idx: this.new_plugin_consumer.idx()
		});

		this.new_access_role(null);
	}

	btnRemovePluginConsumer_click (obj)
	{
		this.process_config.process_roles.remove((item) => {
			return item.role_name == obj.role_name;
		});
	}
}

class EditProcessDefinitionDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.name = 'EditProcessDefinitionDialog';
		this.viewModel = new EditProcessViewModel(this);
		this.process = null;

		this.init();
	}

	async init ()
	{
		let data = await Promise.all([
			Grape.cache.get('BGWorker.ProcessScriptPaths'),
			Grape.cache.get('BGWorker.ProcessSchemas'),
			Grape.cache.get('BGWorker.PluginHandlers'),
			Grape.cache.get('AccessRoles')]);
		
		this.viewModel.available_script_path_names(data[0]);
		this.viewModel.available_process_schemas(data[1]);
		this.viewModel.available_handlers(data[2]);
		this.viewModel.access_roles(data[3]);

		if (this.bindings?.process)
		{
			this.process = this.bindings.process;

			this.viewModel.process_config.process_id(this.process.process_id);
			this.viewModel.process_config.load();
		}
	}

	btnClose_click ()
	{
		this.close(false);
	}

	async btnSave_click ()
	{
		const obj = this.viewModel.process_config.serialize();

		await Grape.fetches.postJSON('/api/bgworker/process', obj);

		Grape.alerts.alert({
			type: 'success',
			title: 'Process saved',
			message: 'Process was saved successfuly'
		});
		this.close(true);
	}
}

export default {
	name: 'EditProcess',
	dialog_class: EditProcessDefinitionDialog,
	template: template,
	provider: "ps"
}
