/**
 */

export default {
	name: 'BGWorker.ProcessSchemas',
	options: {
		refresh: async function() {
			const result = await Grape.fetches.getJSON('/api/bgworker/lookup/schemas?type=DB');
			return result.names || [];
		}
	}
};

