
import template from './bgworker-status-bar.html';
import BGWorkerStatusVM from './BGWorkerStatusVM.js';

export default {
	name: 'ko-bgworker-status-bar',
	viewModel: BGWorkerStatusVM,
	module_type: 'ko',
	template: template
}

