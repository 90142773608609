
import template from './ViewProcessSchedules.html';
import BGWorkerClient from '../lib/BGWorkerClient.js';

/**
 * @class ViewProcessViewModel
 */
class ViewProcessViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.page_title = ko.observable('');
		this.is_loading = ko.observable(true);
	}
}

/**
 * @class ViewProcessSchedulesDialog
 * @implements {GrapeDialogType}
 */
class ViewProcessSchedulesDialog
{
	constructor (bindings)
	{
		this.viewModel = new ViewProcessViewModel(this);
		this.bindings = bindings;
		this.process = this.bindings.process;
		console.log('bindings=', bindings);

		this.viewModel.page_title(['Process Schedules:', this.process.description].join(' ') || 'Process Details');

		this.init();
	}

	init ()
	{
		function expandValues(obj) 
		{
			let html = '<ul>';
			Object.keys(obj).forEach(function(k) {
				if (k && typeof k === 'object' && value.constructor === Object)
					html += '<li><span class="object-field-name">' + k + '</span>: ' + expandValues(obj[k]) + '</li>';
				else
					html += '<li><span class="object-field-name">' + k + '</span>: <span class="object-field-value">' + obj[k] + '</span></li>';
			});
			html += '</ul>';
			return html;
		}

		function actionFormatter (value, row, index)
		{
			let action_buttons = [];
			switch (row.status)
			{
				case 'New':
					action_buttons = action_buttons.concat([
						//BUTTON: View Log File
						'<a href="javascript:void(0)" class="stop_or_remove_schedule" title="Remove Scheduled Process">',
						'<i class="fa fa-remove" style="color: #FF0000"></i>',
						'</a>&emsp;'
						]);
				break;
				case 'Running':
					action_buttons = action_buttons.concat([
						//BUTTON: View Log File
						'<a href="javascript:void(0)" class="stop_or_remove_schedule" title="Stop Running Process">',
						'<i class="fa fa-stop" style="color: #FF0000"></i>',
						'</a>&emsp;'
						]);
				break;

			}
			return action_buttons.join('');
		}

		function datetimeFormatter (value, row, index) {
			if (value)
				return moment(value).format(Grape.config.date_time_format);
			else
				return '-';
		}

		function logfileFormatter (value, row, index) {
			return `<a href="/api/bgworker/schedules/${row.schedule_id}/log" target="_blank">Download</a>`;
		}

		function statusFormatter (value, row, index) {
			switch (value)
			{
				case 'Error':
					return '<span class="fa fa-exclamation-triangle" style="color: #E74C3C; padding-right: 2px;"></span>' + value;
				case 'New':
					return '<span class="fa fa-file-text-o" style="color: #91AA9D; padding-right: 2px;"></span>' + value;
				case 'Completed':
					return '<span class="fa fa-check" style="color: #79BD8F; padding-right: 2px;"></span>' + value;
				case 'Starting':
				case 'Pending':
				case 'Running':
					return '<span class="fa fa-play" style="color: #00A388; padding-right: 2px;"></span>' + value;
				default:
					return value;
			}
		}


		$("#tbl_schedules").bootstrapTable({
			columns: [
				{field: 'status', title: 'Status ', sortable: true, formatter: statusFormatter},
				{field: 'username', title: 'User' , sortable: true },
				{field: 'auto_scheduler_id', title: 'Auto Scheduler ID' , sortable: true },
				{field: 'time_sched', title: '<span class="fa fa-clock-o"></span> Scheduled', sortable: true, formatter: datetimeFormatter},
				{field: 'logfile', title: '<span class="fa fa-folder-open" style="padding-right: 5px"></span> Log file', formatter: (...p) => logfileFormatter(...p)},
				{field: 'time_started', title: '<span class="fa fa-hourglass-start" style="padding-right: 5px"></span> Started ', sortable: true, formatter: datetimeFormatter},
				{field: 'time_ended', title: '<span class="fa fa-hourglass-end" style="padding-right: 5px"></span> Ended ', formatter: datetimeFormatter},
				{
					field: 'action',
					title: '',
					width: '100px',
					formatter: actionFormatter,
					events: {
						'click .stop_or_remove_schedule': async (event, value, row, index) => {
							await BGWorkerClient.stopOrRemoveSchedule(row.schedule_id);
							await Grape.alerts.alert({
								title: 'Success',
								message: 'Process Schedule successfully removed or stopped',
								type: 'success'
							});
							this.updateData();
						}
					}
				}
			],
			data: [],
			dataField: 'records',

			//TABLE PROPERTIES: Paging
			pagination: true,
			pageList: [20, 50, 100],
			pageSize: 20,
			sidePagination: 'server',
			onPageChange: (number, size) => {
				this.updateData();
			},

			//TABLE PROPERTIES: Sort
			sortOrder: 'desc',
			sortName: 'schedule_id',
			onSort: (sortName, sortOrder) => {
				this.updateData();
			},

			//TABLE PROPERTIES: Search
			search: true,
			toolbar: '#dialog_toolbar',
			searchOnEnterKey: true,
			onSearch: (text) => {
				this.updateData();
			},

			detailView: 'true',

			//DISPLAY: More detail data under each row when selected
			detailFormatter: function(event, row, index) {
				return expandValues(row.param);
			}
		});

		this.updateData();
	};

	async updateData ()
	{
		let options = {
			table: 'v_schedules',
			schema: 'bgworker',
			sortfield: 'schedule_id',
			sortorder: 'asc',
			filter: [ { field: 'process_id', operator: '=', value: this.process.process_id } ]
		};

		let table_options = $('#tbl_schedules').bootstrapTable('getOptions');

		//LOGIC: Sort instantiate
		if (table_options.sortName && table_options.sortName != '')
		{
			options.sortfield = table_options.sortName;
			options.sortorder = table_options.sortOrder.toUpperCase();
		}

		//LOGIC: Search instantiate
		if (table_options.searchText && table_options.searchText != "")
		{
			options.filter = [
				{ field: 'logfile', operand: 'ILIKE', value: '%' + table_options.searchText + '%' } ,
				{ field: 'process_id', operator: '=', value: this.process.process_id }
			];
		}

		// Page size and offset instantiate
		if (table_options.pageNumber && table_options.pageSize)
		{
			options.limit = table_options.pageSize;
			options.offset = (table_options.pageNumber - 1) * options.limit;
		}

		let result = await Grape.fetches.getJSON('/api/record', options);

		if (result.status == 'ERROR')
		{
			Grape.alert_api_error(result);
		}
		else
		{
			if (!result.records)
				result.records = [];

			$('#tbl_schedules').bootstrapTable('load', result);

			this.viewModel.is_loading(false);
		}
	}

	btnClose_click ()
	{
		this.close();
	}

	btnRefresh_click ()
	{
		this.viewModel.is_loading(true);
		this.updateData();
	}
}


export default {
	name: 'ViewProcessSchedules',
	dialog_class: ViewProcessSchedulesDialog,
	template: template,
	provider: "ps"
};

