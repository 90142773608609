/**
 */

export default {
	name: 'BGWorker.ProcessScriptPaths',
	options: {
		refresh: async function() {
			const result = await Grape.fetches.getJSON('/api/bgworker/lookup/schemas?type=NODE');
			return result.names || [];
		}
	}
};

