/**
 */

export default {
	name: 'ProcessCategories',
	options: {
		table: 'v_process_categories',
		schema: 'bgworker',
		sortfield: 'category'
	}
};

