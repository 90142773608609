
class ScheduleVM
{
	constructor(obj)
	{
		this.auto_scheduler_id = ko.observable();
		this.param = ko.observable();
		this.pid = ko.observable();
		this.process_id = ko.observable();
		this.process_name = ko.observable();
		this.progress_completed = ko.observable();
		this.progress_total = ko.observable();
		this.result = ko.observable();
		this.schedule_id = ko.observable();
		this.status = ko.observable();
		this.time_ended = ko.observable();
		this.time_sched = ko.observable();
		this.time_started = ko.observable();
		this.username = ko.observable();
		this.can_execute = ko.observable(true);
		if (obj)
			this.set(obj);
	}

	set(obj)
	{
		if (obj.auto_scheduler_id)
			this.auto_scheduler_id(obj.auto_scheduler_id);
		if (obj.param)
			this.param(obj.param);
		if (obj.pid)
			this.pid(obj.pid);
		if (obj.process_id)
			this.process_id(obj.process_id);
		if (obj.process_name)
			this.process_name(obj.process_name);
		if (obj.progress_completed)
			this.progress_completed(obj.progress_completed);
		if (obj.progress_total)
			this.progress_total(obj.progress_total);
		if (obj.result)
			this.result(obj.result);
		if (obj.schedule_id)
			this.schedule_id(obj.schedule_id);
		if (obj.status)
			this.status(obj.status);
		if (obj.time_ended)
			this.time_ended(obj.time_ended);
		if (obj.time_sched)
			this.time_sched(obj.time_sched);
		if (obj.time_started)
			this.time_started(obj.time_started);
		if (obj.username)
			this.username(obj.username);
	}
	
	async load()
	{
		const sched_obj = await Grape.fetches.getJSON(`/api/bgworker/schedule/${this.schedule_id()}`);
		if (sched_obj.status == 'OK')
			this.set(sched_obj.schedule);
	}
}

export default ScheduleVM;

