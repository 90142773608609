
import BGWorkerHandlers from './bgworker-handlers.js';
import Categories from './process-categories.js';
import ScriptPathNames from './process-script-paths.js';
import ProcessSchemas from './process-schemas.js';

export default [
	BGWorkerHandlers,
	Categories,
	ScriptPathNames,
	ProcessSchemas
];
