import template from './CreateSchedule.html';

/**
 * @class CreateScheduleVM
 */
class CreateScheduleVM
{
	constructor (dialog)
	{
		this.dialog = dialog;

		this.is_loading = ko.observable(true);
		this.run_when = ko.observable('run_now');
		this.process_name = ko.observable();
		this.process_id = ko.observable();
		this.sched_date = ko.observable('');
		this.sched_time = ko.observable('00:00');
		this.fields = ko.observableArray([]);
		this.fieldValues = ko.observable({});
	}

	async btnCreateSchedule_click ()
	{
		let params = {};
		for (let [name, $input] of Object.entries(this.fieldValues()))
			params[name] = ($input)();

		let obj = {
			param: params
		};

		if (this.run_when() == 'run_later')
		{
			try {
				let d = new Date(this.sched_date() + ' ' + this.sched_time());
				obj.time_sched = d.toISOString();
			} catch (err) {
				Grape.alerts.alert({type: 'error', title: 'Invalid date', message: 'Please enter a valid time and date'});
				obj.time_sched = null;
			}

			if (!obj.time_sched)
				return;
		}

		const result = await Grape.fetches.postJSON(`/api/bgworker/${this.process_id()}/schedule`, obj);
		if (result.status == 'ERROR')
			Grape.alert_api_error(result);
		else
		{
			Grape.alerts.alert({type: 'success', title: 'Schedule created', message: 'Schedule created successfuly'});
			this.dialog.close(result);
		}
	}
}

/**
 * @class CreateScheduleDialog
 * @image ../../docs/screenshots/start_process_dialog.png
 * @param {Object} bindings
 * @param {String} bindings.process_name
 * @param {Integer} bindings.process_id
 * @param {Object} bindings.params
 */
class CreateScheduleDialog 
{
	constructor (bindings, dialog)
	{
		this.bindings = bindings;
		this.dialog = dialog;
		this.viewModel = new CreateScheduleVM(this);
		this.name = 'CreateScheduleDialog';

		console.log('params=', this.bindings.params);

		this.init();
	}

	init ()
	{
		console.log('init? params=', this.bindings.params);
		this.viewModel.fields(this.bindings.params);
		this.viewModel.process_name(this.bindings.process_name);
		this.viewModel.process_id(this.bindings.process_id);
	}

	btnClose_click ()
	{
		this.close(false);
	}
}

export default {
	name: 'CreateSchedule',
	dialog_class: CreateScheduleDialog,
	template: template,
	provider: "ps"
}
