/**
 */

export default {
	name: 'BGWorker.PluginHandlers',
	options: {
		table: 'v_bgworker_handlers',
		schema: 'bgworker',
		sortfield: 'name'
	}
};

