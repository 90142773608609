
import template from './AutoScheduler.html';

/**
 * @class AutoSchedulerDialogVM
 */
class AutoSchedulerDialogVM
{
	constructor (dialog)
	{
		this.dialog = dialog;

		/**
		* @memberof AutoSchedulerDialogVM
		* @alias active
		* @type {ObservableBoolean}
		*/
		this.active = ko.observable(true);

		/**
		 * Process name
		 * @alias process_name
		 * @memberof AutoSchedulerDialogVM
		 * @type {ObservableString}
		 */
		this.process_name = ko.observable('');
		/**
		 * Process id
		 * @alias process_id
		 * @memberof AutoSchedulerDialogVM
		 * @type {ObservableInteger}
		 */
		this.process_id = ko.observable(null);
		/**
		 * Existing Autoscheduler list
		 * @alias auto_schedulers
		 * @memberof AutoSchedulerDialogVM
		 * @type {ObservableArray}
		 */
		this.auto_schedulers = ko.observableArray([]);

		/**
		 * Currently selected Auto scheduler
		 * @alias 
		 * @memberof AutoSchedulerDialogVM
		 * @type {ObservableInteger}
		 */
		this.auto_scheduler = ko.observable(null);

		/**
		 * Currently selected Auto scheduler ID
		 * @alias autoscheduler_id
		 * @memberof AutoSchedulerDialogVM
		 * @type {ObservableInteger}
		 */
		this.auto_scheduler_id = ko.observable(null);
		
		/**
		 * This value can be used when the view is closed to
		 * determine if a refresh is needed. It is updated
		 * when a schedule is saved or deleted.
		 */
		this.exit_code = ko.observable(0);

		// Autoscheduler options
		this.dow = ko.observable('0000000');
		this.dom = ko.observable('');
		this.day_time = ko.observable('00:00');
		this.scheduled_interval_hr = ko.observable('00');
		this.scheduled_interval_min = ko.observable('00');
		this.scheduled_interval_sec = ko.observable('00');

		// Array with boolean observables for day of month
		this.day_of_months = [];
		for (let i = 1; i < 32; i++)
			this.day_of_months[i] = ko.observable(false);
		this.dom.subscribe((x) => this.update_day_of_months());

		// Array with boolean observables for day of week
		this.day_of_weeks = [];
		for (let i = 0; i < 7; i++)
			this.day_of_weeks[i] = ko.observable(false);
		this.dow.subscribe((x) => this.update_day_of_weeks());

		this.weekday_names = ko.observableArray(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);

		this.scheduled_interval = ko.pureComputed(() => {
			return [
				this.scheduled_interval_hr(), 
				this.scheduled_interval_min(), 
				this.scheduled_interval_sec()
			].join(':');
		}, this);

		// helper 
		this.time_type = ko.observable('tod'); // can be tod or toi

		//this.params = ko.observable({});
		this.params_title = ko.observable('');
		this.params_fields = ko.observableArray([]);
		this.params_inputs = ko.observable({});

		// load
		this.auto_scheduler.subscribe((auto_scheduler) => { this.load(auto_scheduler); });
	}
	
	load (auto_scheduler)
	{
		// Reset values
		this.time_type('tod');
		this.day_time('00:00');
		this.scheduled_interval_hr('0');
		this.scheduled_interval_min('0');
		this.scheduled_interval_sec('0');
		this.dom('');
		this.dow('0000000');

		if (!auto_scheduler || !auto_scheduler.auto_scheduler_id)
			return;

		this.auto_scheduler_id(auto_scheduler.auto_scheduler_id);

		console.debug('Loading Auto Scheduler', auto_scheduler);

		this.active(auto_scheduler.active);

		if (auto_scheduler.day_time && auto_scheduler.day_time != '')
		{
			this.scheduled_interval_hr('0');
			this.scheduled_interval_min('0');
			this.scheduled_interval_sec('0');

			this.time_type('tod');

			if (auto_scheduler.day_time.length > 5)
				auto_scheduler.day_time = auto_scheduler.day_time.slice(0, -3);

			this.day_time(auto_scheduler.day_time);
		}
		else if (auto_scheduler.scheduled_interval && auto_scheduler.scheduled_interval != '')
		{
			this.day_time('');

			this.time_type('ti');

			let split = auto_scheduler.scheduled_interval.split(':');
			this.scheduled_interval_hr(split[0]);
			this.scheduled_interval_min(split[1]);
			this.scheduled_interval_sec(split[2]);
		}

		if (auto_scheduler.days_of_month && auto_scheduler.days_of_month != '')
			this.dom(auto_scheduler.days_of_month);

		if (auto_scheduler.dow && auto_scheduler.dow != '')
			this.dow(auto_scheduler.dow);

		if (auto_scheduler.run_with_params && this.dialog.process.ui_param)
		{
			let process_params = this.dialog.process.ui_param;

			process_params.forEach((f) => {
				if (auto_scheduler.run_with_params[f.name])
					f.default_value = auto_scheduler.run_with_params[f.name];
			});
			this.params_fields(process_params);
		}
	}

	/**
	 * Update day_of_months array after dom() has been updated
	 */
	update_day_of_months ()
	{
		console.log('dom:', this.dom());
		let days = [];
		if (this.dom().length > 0)
			if (this.dom() == '*')
				for (let i = 1; i <= 31; i++)
					days.push(i);
			else
				days = this.dom().split(',').map((x) => parseInt(x));

		for (let i = 1; i < 31; i++)
			if (days.indexOf(i) > -1)
				this.day_of_months[i](true);
			else
				this.day_of_months[i](false);
	}

	//toggle a day of the month on and off
	btnDOM_click (day)
	{
		// toggle
		this.day_of_months[day](!this.day_of_months[day]());
	}

	/**
	 * Update day_of_weeks array after dow() has been updated
	 */
	update_day_of_weeks ()
	{
		for (let i = 0; i < this.dow().length; i++)
		{
			if (this.dow()[i] === '1')
				this.day_of_weeks[i](true);
			else
				this.day_of_weeks[i](false);
		}
	}

	async btnSave_click ()
	{
		let request = {
			auto_scheduler_id: this.auto_scheduler_id(),
			process_id: this.process.process_id,
			dow: '',
			days_of_month: '',
			active: this.active(),
			user_id: this.process.user_id,
			params: {}
		};

		// Get days of week
		for (let i = 0; i < this.day_of_weeks.length; i++)
			if (this.day_of_weeks[i]() === true)
				request.dow += '1';
			else
				request.dow += '0';

		let doms = [];
		// Get days of month
		for (let i = 1; i < 32; i++)
			if (this.day_of_months[i]() === true)
				doms.push(i);
		request.days_of_month = doms.join(',');

		// Get render-fields values
		if (this.process.ui_param)
		{
			let params = {};
			for (let [name, $input] of Object.entries(this.params_inputs()))
				params[name] = ($input)();
			request.params = params;
		}

		// 'Time of day' or 'Interval'
		if (this.time_type() == 'tod')
			request.day_time = this.day_time();
		else 
			request.scheduled_interval = this.scheduled_interval();

		const result = await Grape.fetches.postJSON(`/api/bgworker/process/${this.process.process_id}/autoscheduler`, request);

		if (result.status == 'ERROR')
		{
			Grape.alert_api_error(result);
			return;
		}

		this.exit_code(1);

		Grape.alerts.alert({
			type: 'success',
			title: 'AutoSchedule Process',
			message: 'Auto scheduler saved'});

		this.dialog.close();
	}

	/**
	 * Event handler for delete button click
	 * @memberof AutoSchedulerDialog
	 */
	async btnDelete_click ()
	{
		if (!this.auto_scheduler_id())
			return;

		let accept = await Grape.alerts.confirm({type: 'warning', title: 'Confirm delete', message: 'Are you sure you want to delete this auto scheduler?'});
		if (!accept)
			return;
		
		const result = await Grape.fetches.fetch(
			`/api/bgworker/process/${this.process_id()}/autoscheduler/${this.auto_scheduler_id()}`,
			{
				method: 'DELETE'
			});

		Grape.alerts.alert({type: 'success', title: 'Deleted', message: 'Autoscheduler has been deleted'});
		
		this.exit_code(1);

		this.dialog.close(true);
	}
}

/**
 * @class AutoSchedulerDialog
 * @implements {GrapeDialogType}
 * @image autoschedulerdialog.png
 * @param {Object} bindings
 * @param {Object} bindings.autoscheduler
 * @param {Object} bindings.process
 * @param {Object} bindings.process
 * @param {String} bindings.process.process_name
 * @param {Integer} bindings.process.process_id
 * @param {Object[]} bindings.process.ui_params
 * @example
 * 
 * window.Grape.show_dialog('AutoSchedulerDialog', {process: {process_name: 'SampleProcess', process_id: 1, ui_param: {}, autoscheduler: []}});
 *
 */
class AutoSchedulerDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.process = bindings.process;
		this.autoscheduler = bindings.autoscheduler || null;

		console.log('bindings:',bindings);

		/**
		 * @memberof AutoSchedulerDialog
		 * @name viewModel
		 * @type {AutoSchedulerDialogVM}
		 */
		this.viewModel = new AutoSchedulerDialogVM(this);
		this.viewModel.process = this.process;

		this.init();
	}

	/**
	 * Init function
	 * @memberof AutoSchedulerDialog
	 * @alias init
	 */
	async init () 
	{
		this.viewModel.process_name(this.process.process_name);
		this.viewModel.process_id(this.process.process_id);

		if (this.process.ui_param)
		{
			this.viewModel.params_fields(this.process.ui_param);
			this.viewModel.params_title('Schedule Parameters');
		}
		this.viewModel.auto_schedulers(this.process.auto_schedulers || []);

		if (this.autoscheduler)
			this.viewModel.auto_scheduler(this.autoscheduler);
	}

	/**
	 * Event handler for close button
	 * @memberof AutoSchedulerDialog
	 * @alias btnclose_click
	 */
	btnClose_click ()
	{
		this.close(this.viewModel.exit_code());
	}
}

export default {
	name: 'AutoSchedulerDialog',
	dialog_class: AutoSchedulerDialog,
	template: template,
	provider: "ps"
}
